import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface ISubLink {
    className?: string;
    text?: string;
    href?: string;
    ariaLabel?: string;
    index: string;
    noclick?: boolean;
    isActive: boolean;
    telemetryContent: ITelemetryContent;
    handleHover: Function;
    recordId: number;
}

export const SubLink: React.FC<ISubLink> = ({ text, className, href, ariaLabel, index, noclick, telemetryContent, isActive, handleHover, recordId }) => {

    const payLoad = getPayloadObject('click', telemetryContent, '', '');

    const subLinkText = text !== undefined ? text : '';
    payLoad.contentAction.etext = subLinkText;
    // Construct telemetry attribute to render
    const attribute = getTelemetryAttributes(telemetryContent, payLoad);

    return (
        <a 
            {...attribute}
            className={`${className} ${isActive ? 'active' : ''}`}
            href={href}
            aria-label={ariaLabel}
            onClick={onTelemetryClick(telemetryContent, payLoad, subLinkText)}
            {...(noclick && { 'data-no-click': true })}
            onMouseEnter={() => handleHover(recordId, true)}
            onMouseLeave={() => handleHover(null, true)}
        >
            <span itemProp='name'>
                {subLinkText}
            </span>
            <meta itemProp='position' content={index} />
        </a>
    );
};